html {
  background-color: #fff;
  display: flex;
  flex:1;
  /* height: 100%; */
  color: #000;
}
body {
  margin: 10%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 0px;
}

h2 {
  font-weight: 300;
  font-size: 20px;
}

button.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 45px;
  border:solid 1px #C9B3F6;
  box-sizing: border-box;
  border-radius: 4px;
  background-color:#fff;
  color: #000;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin: 50px 0 24px;
  cursor: pointer;
  box-shadow: 4px 4px 0px #C9B3F6;
}

button span {
  margin-left: 20px;
}

button.button:hover {
  border-color: #000;
  box-shadow: 4px 4px 0px #000;
}

.linkButton {
  display: block;
  background: none!important;
  border: none;
  padding: 0!important;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.linkButton:hover {
  color: #5906ff
}

div.keychatIdResults a {
  margin:6px 0 26px;
}

div.keychatIdResults span {
  color: #5906ff;
}
